import './js/_2fa';
import './js/_checkboxMagic';
import './js/_charts';
import './js/_ctrlPlusS';
import './js/_menu';
import './js/_multiLangSelect';
import './js/_sortable';
import './js/_survey';
import './js/_tooltip';
import './js/_alertButtons';
