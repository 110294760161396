import $ from 'jquery';

// listen to body for ctrl + s
$('body').on('keydown', function (e) {
    if ((e.ctrlKey || e.metaKey) && e.key === 's')  {

        // find the current form that has an element in focus
        const $form = $(':focus').closest('form');

        // if there is a form, submit it
        if ($form.length) {
            e.preventDefault();
            $form.submit();
        }
    }
});

// Test apex charts here
const target = document.querySelector('#chart-20235');