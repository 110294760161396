import $ from 'jquery';
import createSortable from './_sortable';

const $createSurveyForm = $('.create-survey-form');
if ($createSurveyForm.length) {
    const $document = $(document.documentElement);
    const activeLanguage = $(document.body).data('activeLanguage');

    const $chapterSortableContainer = $('.chapter-sortable-container');

    /**
     * Indexes
     */

    function setIndex($element, index) {
        $element.data('index', index);
        $element.attr('data-index', index);
    }

    function getIndexesForElement($element) {
        let [chapterIndex, sectionIndex, questionIndex, answerIndex] = [];
        if ((chapterIndex = $element.closest('[data-entity-type="chapter"]').data('index')) !== undefined) {
            if ((sectionIndex = $element.closest('[data-entity-type="section"]').data('index')) !== undefined) {
                if ((questionIndex = $element.closest('[data-entity-type="question"]').data('index')) !== undefined) {
                    answerIndex = $element.closest('[data-entity-type="answer"]').data('index');
                }
            }
        }
        return [chapterIndex, sectionIndex, questionIndex, answerIndex];
    }

    function updateEntityIndexes($wrapper, index) {
        setIndex($wrapper, index);
        const entityType = $wrapper.data('entityType');
        $wrapper.find('[data-template-name]').each(function (i, input) {
            const $input = $(input);
            const name = $input.attr('name');
            const templateName = $input.attr('data-template-name');
            if (name.includes(`%${entityType}Index`))
                $input.attr('name', name.replace(`%${entityType}Index`, index));
            else
                $input.attr('name', templateName.replace(`%${entityType}Index`, index));
        });
    }

    function updateAllIndexes() {
        $createSurveyForm.find('[data-entity-type="chapter"]').each(function (chapterIndex, chapterWrapper) {
            const $chapterWrapper = $(chapterWrapper);
            updateEntityIndexes($chapterWrapper, chapterIndex);
            $chapterWrapper.find('[data-entity-type="section"]').each(function (sectionIndex, sectionWrapper) {
                const $sectionWrapper = $(sectionWrapper);
                updateEntityIndexes($sectionWrapper, sectionIndex);
                $sectionWrapper.find('[data-entity-type="question"]').each(function (questionIndex, questionWrapper) {
                    const $questionWrapper = $(questionWrapper);
                    updateEntityIndexes($questionWrapper, questionIndex);
                    $questionWrapper.find('.independent-sortable-container').each(function (i, sortableContainer) {
                        $(sortableContainer).find('.draggable-container').each(function (answerIndex, answerWrapper) {
                            updateEntityIndexes($(answerWrapper), answerIndex);
                        });
                    });
                    $questionWrapper.find('.js-condition-wrapper').each(function (conditionIndex, conditionWrapper) {
                        $(conditionWrapper).find('[name*="%conditionIndex"]').each(function (i, input) {
                            const $input = $(input);
                            const name = $input.attr('name');
                            $input.attr('name', name.replace('%conditionIndex', conditionIndex));
                        });
                    });
                });
            });
        });

        $chapterSortableContainer.find('[data-entity-type="chapter"]').each((chapterIndex, chapterDraggableContainer) => {
            const $chapterDraggableContainer = $(chapterDraggableContainer);
            setIndex($chapterDraggableContainer, chapterIndex);
            $chapterDraggableContainer.find('[data-entity-type="section"]').each((sectionIndex, sectionDraggableContainer) => {
                const $sectionDraggableContainer = $(sectionDraggableContainer);
                setIndex($sectionDraggableContainer, sectionIndex);
                $sectionDraggableContainer.find('[data-entity-type="question"]').each((questionIndex, questionDraggableContainer) => {
                    setIndex($(questionDraggableContainer), questionIndex);
                });
            });
        });
    }

    function updateIndexesForEntity($sortableContainer, entityType) {
        const [fromChapterIndex, sectionIndex, questionIndex] = getIndexesForElement($sortableContainer);
        $sortableContainer.find('> [data-index]').each(function (entityIndex, wrapper) {
            const $wrapper = $(wrapper);
            setIndex($wrapper, entityIndex);
            $wrapper.find('[data-template-name]').each(function (i, input) {
                const $input = $(input);
                const templateName = $input.data('templateName');
                let name = templateName.replace('%chapterIndex', fromChapterIndex)
                    .replace('%sectionIndex', sectionIndex)
                    .replace('%questionIndex', questionIndex)
                    .replace(`%${entityType}Index`, entityIndex);
                $input.attr('name', name);
            });
        });
    }

    /**
     * Add/Delete buttons
     */

    $document.on('click', '.js-add-button', function () {
        const $this = $(this);
        const templateId = $this.data('templateId');
        const entityType = $this.data('targetEntityType');
        const draggableTemplateId = $this.data('draggableTemplateId');
        const $clonedTemplateContent = $(`#${templateId}`).contents().clone();
        const $clonedDraggableTemplateContent = $(`#${draggableTemplateId}`).contents().clone();

        if (entityType === 'chapter') {
            $createSurveyForm.append($clonedTemplateContent);
            $chapterSortableContainer.append($clonedDraggableTemplateContent);

            updateAllIndexes();
            return;
        }

        $clonedTemplateContent.insertBefore($this);

        const [chapterIndex, sectionIndex] = getIndexesForElement($this);

        let sortableContainerSelector = `[data-entity-type="chapter"][data-index="${chapterIndex}"]`;
        if (sectionIndex !== undefined)
            sortableContainerSelector += ` [data-entity-type="section"][data-index="${sectionIndex}"]`;
        sortableContainerSelector +=  ` .${entityType}-sortable-container`;

        const $sortableContainer = $chapterSortableContainer.find(sortableContainerSelector);
        $sortableContainer.append($clonedDraggableTemplateContent);

        $clonedDraggableTemplateContent.find('[class*="-sortable-container"]').each(function (i, sortableContainer) {
            createSortable(sortableContainer, handleOnSortableEnd);
        });

        const $titleInputCheckboxParent = $clonedTemplateContent.find('.func-title-input-checkbox-parent');
        if ($titleInputCheckboxParent.length) {
            $titleInputCheckboxParent.find('.func-title-input-checkbox').prop('checked', true);
            const selectedLanguage = $titleInputCheckboxParent.find('.language-select input[type=radio]:checked').val();
            $titleInputCheckboxParent.find(`.language-input input[data-language="${selectedLanguage}"]`).first().trigger("focus");
        }

        updateAllIndexes();
    });

    $document.on('click', '.js-delete-button', function () {
        const $this = $(this);
        const [chapterIndex, sectionIndex, questionIndex] = getIndexesForElement($this);

        let containerSelector = `[data-entity-type="chapter"][data-index="${chapterIndex}"]`;
        if (sectionIndex !== undefined) {
            containerSelector += ` [data-entity-type="section"][data-index="${sectionIndex}"]`;
            if (questionIndex !== undefined)
                containerSelector += ` [data-entity-type="question"][data-index="${questionIndex}"]`;
        }

        $createSurveyForm.find(containerSelector).remove();
        $chapterSortableContainer.find(containerSelector).remove();

        updateAllIndexes();
    });

    $document.on('click', '.js-copy-button', function () {
        const $this = $(this);

        const [chapterIndex, sectionIndex, questionIndex] = getIndexesForElement($this);

        let containerSelector = `[data-entity-type="chapter"][data-index="${chapterIndex}"]`;
        if (sectionIndex !== undefined) {
            containerSelector += ` [data-entity-type="section"][data-index="${sectionIndex}"]`;
            if (questionIndex !== undefined)
                containerSelector += ` [data-entity-type="question"][data-index="${questionIndex}"]`;
        }

        const $draggableContainer = $chapterSortableContainer.find(containerSelector);
        const $clonedDraggableContainer = $draggableContainer.clone();
        $draggableContainer.after($clonedDraggableContainer);

        $clonedDraggableContainer.find('[class*="-sortable-container"]').each(function (i, sortableContainer) {
            createSortable(sortableContainer, handleOnSortableEnd);
        });

        const $entityWrapper = $createSurveyForm.find(containerSelector);
        const $clonedEntityWrapper = $entityWrapper.clone();
        $clonedEntityWrapper.find('[name*="[id]"]').val(null);
        $entityWrapper.after($clonedEntityWrapper);
        if ($entityWrapper.data('entityType') === 'chapter')
            $entityWrapper.after('<hr class="mt-7 border-gray">');

        updateAllIndexes();
    });

    $document.on('change', '.js-question-type-select', function () {
        $this = $(this);
        const $selectedOption = $this.find(':selected');
        const templateId = $selectedOption.data('templateId');
        const $clonedTemplateContent = $(`#${templateId}`).contents().clone();
        const $questionWrapper = $this.closest('.question-wrapper');
        const $questionContentWrapper = $questionWrapper.find('.question-content-wrapper');
        $questionContentWrapper.empty().append($clonedTemplateContent);

        const $sortableContainers = $questionContentWrapper.find('.independent-sortable-container');
        if ($sortableContainers.length)
            $sortableContainers.each(function (i, sortableContainer) {
                const $sortableContainer = $(sortableContainer);
                updateIndexesForEntity($sortableContainer, $sortableContainer.data('sortableEntityType'));
                createSortable(sortableContainer, handleOnIndependentSortableEnd);
            });
    });

    $document.on('click', '.js-add-entity-button', function () {
        const $this = $(this);
        const templateId = $this.data('templateId');
        const wrapperSelector = $this.data('targetContainerSelector');
        const entityType = $this.data('targetEntityType');
        const $clonedTemplateContent = $(`#${templateId}`).contents().clone();
        const $targetContainer = $this.closest('.question-wrapper').find(wrapperSelector);
        $targetContainer.append($clonedTemplateContent);
        updateIndexesForEntity($targetContainer, entityType);
    });

    $document.on('click', '.js-delete-entity-button', function () {
        const $this = $(this);
        const $wrapper = $this.closest('[data-entity-type]');
        const wrapperSelector = $this.data('targetContainerSelector');
        const entityType = $this.data('targetEntityType');
        $wrapper.remove();
        const $targetContainer = $this.closest('.question-wrapper').find(wrapperSelector);
        updateIndexesForEntity($targetContainer, entityType);
    });

    $document.on('click', '.js-add-condition-button', function () {
        const $this = $(this);
        const $clonedTemplateContent = $('#condition-template').contents().clone();
        const $targetContainer = $this.closest('.question-wrapper').find('.conditions-container');
        $targetContainer.append($clonedTemplateContent);
        updateIndexesForEntity($targetContainer, 'condition');
    });

    $document.on('click', '.js-question-condition-select', function () {
        const $this = $(this);

        // get selected question index
        const selectedQuestionIndex = $this.val();

        const [chapterIndex, sectionIndex] = getIndexesForElement($this);
        const questionOptionsData = [];

        $(`.create-survey-form [data-index=${chapterIndex}][data-entity-type=chapter] [data-index=${sectionIndex}][data-entity-type=section] [data-entity-type=question]`).each(function () {
            const $this = $(this);
            const questionIndex = $this.data('index');
            const questionText = $this.find(`[data-key="questionText"][data-language=${activeLanguage}]`).val();

            questionOptionsData.push({ questionIndex, questionText });
        });

        // generate options based on all questions in this section
        $this.find('option').remove();

        $this.append('<option value="">...</option>');
        questionOptionsData.forEach(({ questionIndex, questionText }) => {
            $this.append(`<option value="${questionIndex}">${questionText}</option>`);
        });

        // set selected question index
        $this.val(selectedQuestionIndex);
    });

    $document.on('change', '.js-question-condition-select', function () {
        console.log('fire?');
        // get the answers for the current questionIndex
        const $this = $(this);
        const questionIndex = $this.val();

        const [chapterIndex, sectionIndex] = getIndexesForElement($this);

        const answerOptionsData = [];
        $(`.create-survey-form [data-index=${chapterIndex}][data-entity-type=chapter] [data-index=${sectionIndex}][data-entity-type=section] [data-entity-type=question][data-index=${questionIndex}] [data-entity-type=answer]`).each(function () {
            const $this = $(this);
            const answerIndex = $this.data('index');
            const answerText = $this.find(`[data-key="answer"][data-language=${activeLanguage}]`).val();

            answerOptionsData.push({ answerIndex, answerText });
        });

        $answerSelect = $this.closest('.js-answer-condition-select')

        const selectedAnswerIndex = $answerSelect.val();

        $answerSelect.find('option').remove();
        console.log($answerSelect, answerOptionsData);
        if(answerOptionsData.length === 0) {
            $answerSelect.append('<option disabled selected value="">✅</option>');

        } else {

            $answerSelect.append('<option value="">...</option>');
            answerOptionsData.forEach(({ answerIndex, answerText }) => {
                $answerSelect.append(`<option value="${answerIndex}">${answerText}</option>`);
            });

            $answerSelect.val(selectedAnswerIndex);
        }
    });

    /**
     * Sortable
     */

    createSortable($chapterSortableContainer[0], (sortableEvent) => {
        const { item: draggableContainer } = sortableEvent;
        const $draggableContainer = $(draggableContainer);
        const oldIndex = $draggableContainer.data('index');
        $draggableContainer.closest('.chapter-sortable-container').find('[data-entity-type="chapter"]').each(function (chapterIndex, draggableContainer) {
            const $draggableContainer = $(draggableContainer);
            setIndex($draggableContainer, chapterIndex);
        });
        const newIndex = $draggableContainer.data('index');
        if (newIndex === oldIndex) return;
        const $chapterWrappers = $createSurveyForm.find('[data-entity-type="chapter"]');
        if (newIndex === $chapterWrappers.length - 1)
            $createSurveyForm.append($chapterWrappers[oldIndex]);
        else {
            const insertThis = $chapterWrappers[oldIndex];
            const indexToInsert = newIndex + (newIndex > oldIndex ? 1 : 0);
            const beforeThis = $chapterWrappers[indexToInsert];
            beforeThis.before(insertThis);
        }

        updateAllIndexes();
    });

    function handleOnSortableEnd (sortableEvent) {
        const { from, item: draggableContainer, to } = sortableEvent;
        const $draggableContainer = $(draggableContainer);
        const entityType = $draggableContainer.data('entityType');
        const oldIndex = $draggableContainer.data('index');

        const $from = $(from);
        const $to = $(to);

        $from.find(`[data-entity-type="${entityType}"]`).each(function (index, draggableContainer) {
            const $draggableContainer = $(draggableContainer);
            setIndex($draggableContainer, index);
        });
        $to.find(`[data-entity-type="${entityType}"]`).each(function (index, draggableContainer) {
            const $draggableContainer = $(draggableContainer);
            setIndex($draggableContainer, index);
        });
        const newIndex = $draggableContainer.data('index');

        const [fromChapterIndex, fromSectionIndex] = getIndexesForElement($from);
        const [toChapterIndex, toSectionIndex] = getIndexesForElement($to);

        let insertSelector = `[data-entity-type="chapter"][data-index="${fromChapterIndex}"] [data-entity-type="section"][data-index`; // Intentionally left open, closes after checks
        let toParentSelector = `[data-entity-type="chapter"][data-index="${toChapterIndex}"]`;
        if (oldIndex === newIndex && fromChapterIndex === toChapterIndex) {
            if (fromSectionIndex !== undefined && toSectionIndex !== undefined) {
                if (fromSectionIndex === toSectionIndex) return;

                insertSelector += `="${fromSectionIndex}"] [data-entity-type="question"][data-index`; // Intentionally left open, closes after checks
                toParentSelector += ` [data-entity-type="section"][data-index="${toSectionIndex}"]`;
            } else return;
        }
        insertSelector += ']';

        const insertThis = $createSurveyForm.find(insertSelector)[oldIndex];

        const $toParent = $createSurveyForm.find(toParentSelector);
        const $siblings = $toParent.find(`[data-entity-type="${entityType}"]`);

        if (newIndex === $siblings.length) {
            $toParent.find(`.js-add-${entityType}-button`).before(insertThis);
        } else {
            $siblings[newIndex].before(insertThis);
        }

        updateAllIndexes();
    }

    $('.section-sortable-container, .question-sortable-container').each(function (i, sortableContainer) {
        createSortable(sortableContainer, handleOnSortableEnd);
    });

    function handleOnIndependentSortableEnd(sortableEvent) {
        const { from, to } = sortableEvent;
        const $from = $(from);
        updateIndexesForEntity($from, $from.data('sortableEntityType'));
        if (to !== from) {
            const $to = $(to);
            updateIndexesForEntity($to, $to.data('sortableEntityType'));
        };
    }

    $('.independent-sortable-container').each(function (i, sortableContainer) {
        const $sortableContainer = $(sortableContainer);
        updateIndexesForEntity($sortableContainer, $sortableContainer.data('sortableEntityType'));
        createSortable(sortableContainer, handleOnIndependentSortableEnd);
    });

    /**
     * Title input
     */

    $document.on('change', '.func-title-input-checkbox-parent .func-title-input-checkbox', function () {
        $this = $(this);
        if ($this.prop('checked')) {
            const $titleInputCheckboxParent = $this.closest('.func-title-input-checkbox-parent');
            const selectedLanguage = $titleInputCheckboxParent.find('.language-select input[type=radio]:checked').val();
            $titleInputCheckboxParent.find(`.language-input input[data-language="${selectedLanguage}"]`).trigger("focus");
        }
    });

    $document.on('blur', `.func-title-input-checkbox-parent .language-input input[type=text][data-language="${activeLanguage}"], .question-wrapper .language-input input[data-language="${activeLanguage}"]`, function () {
        const $this = $(this);
        const value = $this.val();
        $this.closest('.func-title-input-checkbox-parent').find('.func-title-input-title').text(value);

        const [chapterIndex, sectionIndex, questionIndex] = getIndexesForElement($this);

        let selector = `[data-entity-type="chapter"][data-index="${chapterIndex}"]`;
        if (sectionIndex !== undefined) {
            selector += ` [data-entity-type="section"][data-index="${sectionIndex}"]`;

            if (questionIndex !== undefined)
                selector += ` [data-entity-type="question"][data-index="${questionIndex}"]`;
        }
        selector += ' .js-draggable-label';

        const $draggableLabel = $chapterSortableContainer.find(selector).first();
        $draggableLabel.text(value);
    });
}
