import ApexCharts from "apexcharts"
import $ from 'jquery';

const options = {
    series: [{
        name: 'RODS Score', // dynamic
        data: [28, 39, 30, 31, 4, 123, 5, 123] // dynamic
    }],
    chart: {
        type: 'line',
        stacked: false,
        height: 350,
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
        },
        toolbar: {
            autoSelected: 'zoom'
        }
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0,
    },
    title: {
        text: 'Stock Price Movement',
        align: 'left'
    },
    yaxis: {
        title: {
            text: 'Amount in RODS'
        },
    },
    xaxis: {
        categories: ['M4', 'M5', 'M6', 'M7', 'M8', 'M9'], // should be dynamic from dataset
    },
    tooltip: {
        shared: false,
    }
};

$('.js-chart').each(function () {
    const chart = new ApexCharts(this, options);

    console.log(this.dataset) // -- NOTE: change options based on the data set before creating the chart
    // stuff with dataset to make the options dynamic
    chart.render();
});





// hier charts initializen

